module.exports = ['$scope', '$q', '$location', 'WizardState', 'Survey', 'sriClient', 'Utils', 'Organisations',
  async function ($scope, $q, $location, WizardState, Survey, sriClient, Utils, Organisations) { //eslint-disable-line


    $scope.$emit('wellbe_wizard_visibility', { visible: false });
    $scope.$emit('wellbe_header_logoVisibility', { visible: true });

    Survey.clear();

    $scope.schools = await Organisations.getRelevantOrgUnitsForUser();

    const userNumBoardings = $scope.schools.filter(school => school.type === 'BOARDING').length;
    const userNumLeersteuncentra = $scope.schools.filter(school => school.type === 'LEERSTEUNCENTRUM').length;
    const userNumSchools = $scope.schools.filter(school => school.type === 'SCHOOLENTITY').length;

    if(userNumSchools === $scope.schools.length) {
      $scope.institutionText = ['Welke school', 'school', 'de school'];
    } else if(userNumBoardings === $scope.schools.length) {
      $scope.institutionText = ['Welk internaat', 'internaat', 'het internaat'];
    } else if(userNumLeersteuncentra === $scope.schools.length) { 
      $scope.institutionText = ['Welk leersteuncentrum', 'leersteuncentrum', 'het leersteuncentrum'];
    } else {
      $scope.institutionText = ['Welke school of instelling', 'school of een instelling', 'de school of de instelling'];
    }

    $scope.onChooseSchool = function (school) {
      console.log('on choose school:', school.key);

      Survey.setSchool(school);

      // physical location should be chosen if more than 1 for selected school entity (#15577)
      if (school.locations && school.locations.length > 1) {
        $location.path('/locations');
      } else {
        if (school.locations && school.locations.length === 1) {
          Survey.setPhysicalLocation(school.locations[0].physicalLocation.$$expanded);
        }
        $location.path('/directors');
      }
    };
  }];
