module.exports = { //eslint-disable-line
  preventionAdvisorPosition: {
    name: 'preventie-adviseur',
    href: '/positions/f9435fd7-2776-7007-e040-fd0a049a282a'
  },
  headPreventionAdvisorPosition: '/positions/49e5a584-145e-4ea5-ac2b-1b2b4a2b2d47',

  // to be moved to admin backend
  intakeQuestions: [
    {
      key: '1c9939f0-fda8-fe02-88c5-d6b06ce021e3', number: 1, stopIntake: true, text: 'Zijn er formele aanvragen (klachten) tot psychosociale tussenkomst, gericht aan de vertrouwenspersoon of de preventieadviseur psychosociale aspecten?'
    },
    { key: '2c9939f0-fda8-fe02-88c5-d6b06ce021e3', number: 2, text: 'Is de school of de instelling geherstructureerd (fusie, opslorping, organisatiewijziging) in de loop van de twee voorafgaande schooljaren?' },
    { key: '3c9939f0-fda8-fe02-88c5-d6b06ce021e3', number: 3, text: 'Hebt u weet van groepsconflicten?' },
    { key: '4c9939f0-fda8-fe02-88c5-d6b06ce021e3', number: 4, text: 'Zijn er in het voorbije schooljaar meerdere personeelsleden gedurende een  lange periode (meer dan 30 kalenderdagen) afwezig geweest wegens ziekte gerelateerd aan de arbeidsomstandigheden (bevallings- of ouderschapsverlof niet meegerekend)?' },
    { key: '5c9939f0-fda8-fe02-88c5-d6b06ce021e3', number: 5, text: 'Zijn er, in de loop van voorafgaand schooljaar, personeelsleden geconfronteerd met mogelijks schokkende gebeurtenissen op psychosociaal vlak, hetzij als getuige, hetzij als slachtoffer?' }
  ],

  defaultGroups: [
    { type: 'DIRECTORS', name: 'Directieteam', participants: [] },
    { type: 'TEACHERS', name: 'Leraren', participants: [] },
    { type: 'STAFF', name: 'Ondersteunend personeel', participants: [] }
  ],

  groupTypes: [{ value: 'DIRECTORS', name: 'Directieteam' }, { value: 'TEACHERS', name: 'Leraren' }, { value: 'STAFF', name: 'Ondersteunend personeel' }],

  minGroupParticipants: 6,

  minAnswersForComparation: 5,

  reportColors: ['#E95582', '#e8aeb3', '#F5F5F5', '#b0eeaf', '#98E955'],

  reportSituations: [
    { key: 'before', value: 'Huidige situatie' }, // current situation
    { key: 'after', value: 'Wens tot actie' } // wanted situation
  ],

  possibleAnswers: ['DO_NOT_AGREE_AT_ALL', 'DO_NOT_AGREE', 'DO_NOT_KNOW', 'AGREE', 'TOTALLY_AGREE'],

  directorPosition: { name: 'directeur', href: '/positions/f9435fd7-276e-7007-e040-fd0a049a282a' },
  administratorPosition: { name: 'beheerder', href: '/positions/eaefae68-c188-4618-98c6-effdd848faa7' },
  chairmanPosition: { name: 'voorzitter', href: '/positions/f6a2869e-790b-4d55-aae1-838fe1b8f2b4' },
  CEOPosition: { name: 'algemeen directeur', href: '/positions/f9435fd7-2764-7007-e040-fd0a049a282a'},

  directorsEmail: {
    status: 'QUEUED',
    sender: {
      name: 'WellBe',
      email: 'wellbe@katholiekonderwijs.vlaanderen'
    },
    application: {
      href: '/security/components/wellbe'
    },
    content: {
      frame: { href: '/mailer/templates/ad395f55-afa9-44c1-bd04-18990e80a20b' },
      body: {
        html: 'Body of the mail to the directors.'
      }
    },
    subject: 'Bevraging welzijn op het werk: WellBe',
    globalMergeVariables: [
      {
        name: 'FOOTERWEBSITE',
        content: 'http://wellbe.katholiekonderwijs.vlaanderen/'
      },
      {
        name: 'FOOTERCONTACTURL',
        content: 'mailto:wellbe@katholiekonderwijs.vlaanderen'
      },
      {
        name: 'TWITTERURL'
      },
      {
        name: 'FACEBOOKURL'
      },
      {
        name: 'HEADER',
        content: '<h1 style="font-size: 28px; font-weight:normal; font-family: arial,sans-serif; text-align: right; padding-right: 15px;"><span style="color: #909;">well</span>be.vlaanderen</h1>'
      },
      {
        name: 'TITLE',
        content: 'WellBe'
      }
    ]
  },

  directorsBodyTemplateWarning: '/mailer/templates/1412664a-0b02-45d8-80bb-d95356bcfaf0',
  directorsBodyTemplate: '/mailer/templates/2412664a-0b02-45d8-80bb-d95356bcfaf0',

  participantsBodyTemplate: '/mailer/templates/3412664a-0b02-45d8-80bb-d95356bcfaf0',

  firstSchoolSurveyBodyTemplate: '/mailer/templates/6412664a-5b02-45d8-80bb-d95356bcfaf0',

  generalTemplateEmail: {
    status: 'QUEUED',
    sender: {
      name: 'WellBe',
      email: 'wellbe@katholiekonderwijs.vlaanderen'
    },
    application: {
      href: '/security/components/wellbe'
    },
    content: {
      // frame: { href: '/mailer/templates/522962c7-900b-3795-3969-08c90e2cf401' }, // wellbe frame
      frame: { href: '/mailer/templates/ad395f55-afa9-44c1-bd04-18990e80a20b' }, // general template frame
      body: {
        // html: 'Body of the mail to the participant (to be replaced).'
      }
    },
    subject: 'Bevraging welzijn op het werk: WellBe',
    globalMergeVariables: [
      {
        name: 'FOOTERWEBSITE',
        content: 'http://wellbe.katholiekonderwijs.vlaanderen/'
      },
      {
        name: 'FOOTERCONTACTURL',
        content: 'mailto:wellbe@katholiekonderwijs.vlaanderen'
      },
      {
        name: 'TWITTERURL'
      },
      {
        name: 'FACEBOOKURL'
      },
      {
        name: 'HEADER',
        content: '<h1 style="font-size: 28px; font-weight:normal; font-family: arial,sans-serif; text-align: right; padding-right: 15px;"><span style="color: #909;">well</span>be.vlaanderen</h1>'
      },
      {
        name: 'TITLE',
        content: 'WellBe'
      }
    ]
  },

  preventieAdvisorEmails: [
    {
      type: 'NEW_SURVEY_SENT',
      subject: 'Bevraging welzijn op het werk: WellBe.Vlaanderen',
      // body: 'Beste preventie-adviseur,<br><br>' +
      // 'De bevraging werd verstuurd naar de deelnemers van {{SCHOOL_AND_LOCATIONS}}.<br><br>' +
      // 'Als de deelnemer geen mail ontving, kan hij best ook even in zijn SPAM kijken. <br><br>' +
      // 'Over een uurtje kan je in <a href="http://wellbe.katholiekonderwijs.vlaanderen">WellBe.vlaanderen</a> kijken of de mails succesvol verstuurd werden. De mails die niet verstuurd werden, worden aangeduid met <img width="30" height="30" alt="invalid" src="http://testwellbe.katholiekonderwijs.vlaanderen/img/invalid-mail.png"/> in het overzicht. Je kan het email-adres aanpassen en de uitnodiging wordt toch nog verstuurd. <br><br>' +
      // 'Je kan ook een herinnering sturen als de deelnemers de bevraging vergeten in te vullen. <br><br>' +
      // 'Als de bevraging afgelopen is en er zijn voldoende antwoorden geregistreerd om de anonimiteit te garanderen, kan je de resultaten bekijken via de rapporten. <br><br>' +
      // 'Met vriendelijke groeten <br> WellBe',
      bodyTemplate: '/mailer/templates/5412664a-1b02-45d8-80bb-d95356bcfaf0'
    },
    {
      type: 'REMINDERS_SENT',
      subject: 'Herinnering bevraging welzijn op het werk: WellBe.Vlaanderen',
      // body: 'Beste preventie-adviseur,<br><br>' +
      // 'De herinnering werd verstuurd naar de deelnemers van {{SCHOOL_AND_LOCATIONS}} die de bevraging nog niet invulden. <br><br>' +
      // 'Over een uurtje kan je in <a href="http://wellbe.katholiekonderwijs.vlaanderen">WellBe.vlaanderen</a> kijken of de herinnering succesvol verstuurd werd. De mails die niet verstuurd werden, worden aangeduid met <img width="30" height="30" alt="invalid" src="http://testwellbe.katholiekonderwijs.vlaanderen/img/invalid-mail.png"/> in het overzicht. Je kan het email-adres aanpassen en de herinnering wordt toch nog verstuurd. <br><br>' +
      // 'Zolang de einddatum van de bevraging niet bereikt is, kan je de einddatum veranderen en kan je de bevraging dus langer openstellen. <br><br>' +
      // 'Als de bevraging afgelopen is en er zijn voldoende antwoorden geregistreerd om de anonimiteit te garanderen, kan je de resultaten bekijken via de rapporten. <br><br>' +
      // 'Met vriendelijke groeten <br> WellBe' ,
      bodyTemplate: '/mailer/templates/5412664a-2b02-45d8-80bb-d95356bcfaf0'
    },
    {
      type: 'NEW_EMAILS_SENT',
      subject: 'Bevraging welzijn op het werk: WellBe.Vlaanderen',
      // body: 'Beste preventie-adviseur,<br><br>' +
      // 'De bevraging werd verstuurd naar de nieuwe deelnemers van {{SCHOOL_AND_LOCATIONS}}.<br><br>' +
      // 'Als de deelnemer geen mail ontving, kan hij best ook even in zijn SPAM kijken. <br><br>' +
      // 'Over een uurtje kan je in <a href="http://wellbe.katholiekonderwijs.vlaanderen">WellBe.vlaanderen</a> kijken of de mail succesvol verstuurd werd. De mails die niet verstuurd werden, worden aangeduid met <img width="30" height="30" alt="invalid" src="http://testwellbe.katholiekonderwijs.vlaanderen/img/invalid-mail.png"/> in het overzicht. Je kan het email-adres aanpassen en de uitnodiging wordt toch nog verstuurd. <br><br>' +
      // 'Als de bevraging afgelopen is en er zijn voldoende antwoorden geregistreerd om de anonimiteit te garanderen, kan je de resultaten bekijken via de rapporten. <br><br>' +
      // 'Met vriendelijke groeten <br> WellBe' ,
      bodyTemplate: '/mailer/templates/5412664a-3b02-45d8-80bb-d95356bcfaf0'
    },
    {
      type: 'EDITED_PARTICIPANT_EMAIL',
      subject: 'Bevraging welzijn op het werk: WellBe.Vlaanderen',
      // body: 'Beste preventie-adviseur,<br><br>' +
      // 'De bevraging werd verstuurd naar {{PARTICIPANT_EMAIL}} van {{SCHOOL_AND_LOCATIONS}}.<br><br>' +
      // 'Als de deelnemer geen mail ontving, kan hij best ook even in zijn SPAM kijken. <br><br>' +
      // 'Over een uurtje kan je in <a href="http://wellbe.katholiekonderwijs.vlaanderen">WellBe.vlaanderen</a> kijken of deze mail succesvol verstuurd werd. Als de mail opnieuw niet verstuurd werd, wordt het aangeduid met <img width="30" height="30" alt="invalid" src="http://testwellbe.katholiekonderwijs.vlaanderen/img/invalid-mail.png"/> in het overzicht. Je kan het email-adres opnieuw aanpassen en de uitnodiging wordt toch nog verstuurd. <br><br>' +
      // 'Met vriendelijke groeten <br> WellBe' ,
      bodyTemplate: '/mailer/templates/5412664a-4b02-45d8-80bb-d95356bcfaf0'
    }
  ]
};
