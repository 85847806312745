module.exports = ['$scope', '$q', 'constants', 'dateUtils', '$location', 'Survey', 'Utils', 'vskoModalService', 'Organisations',
  function ($scope, $q, constants, dateUtils, $location, Survey, Utils, vskoModalService, Organisations) { //eslint-disable-line

    'use strict';

    $scope.stepNumber = 1;
    $scope.$emit('wellbe_wizard_step', { stepNumber: $scope.stepNumber });

    $scope.headerTitle = 'Intake';
    $scope.headerDescription = 'We stellen je eerst enkele algemene vragen over het team.';

    $scope.questions = constants.intakeQuestions;

    $scope.qtiTemplatePath = 'S001-simple.xml';

    function isAnsweredYes(question) {
      return question.answer.length > 0 && question.answer[0].identifier === 'YES';
    }

    function sendSurvey() {
      Survey.getSurvey().then(survey => {
        if (!survey) {
          // new survey for the school in the current school year
          let schoolyear = dateUtils.parse(
            dateUtils.getEndOfSchoolYear(dateUtils.getNow())
          ).getFullYear();

          survey = {
            key: Utils.getUUID(),
            // school: { href: '/schools/' + Survey.getSchool().key },
            school: { href: Survey.getSchool().$$meta.permalink },
            location: Survey.getPhysicalLocation() ? { href: Survey.getPhysicalLocation().$$meta.permalink } : null,
            level: Survey.getSchool().level,
            schoolyear: schoolyear
          };
        }

        // save intake answers given by the user
        survey.intakeanswers = $scope.questions
          .filter(qst => qst.answer && qst.answer.length > 0)
          .map(qst => {
            return {
              key: Utils.getUUID(),
              question: { href: '/content/' + qst.key },
              answer: qst.answer[0].identifier
            };
          });

        if ($scope.showAlertAdvice) {
          survey.motivation = '...';
        }

        // send survey resource to the api updated with the given intake answers
        Survey.saveSurvey(survey).then(async () => {
          // TODO send mail to school director and chairman
          let intakeanswers = $scope.questions
            .filter(qst => qst.answer && qst.answer.length > 0)
            .map(qst => {
              return {
                question: qst.text, // NOTE: possibly will change to qst.description
                answer: qst.answer[0].identifier === 'YES' ? 'JA' : 'NEE'
              };
            });

          Survey.sendDirectorsMail(intakeanswers, $scope.showAlertAdvice, Survey.getDirectors());

          let userSurveys = Survey.getCachedUserSurveys();
          let otherSchoolSurvey = userSurveys.find(s => s.school.href === survey.school.href);

          if (!otherSchoolSurvey) {
            // in case it's the first survey for the school we send a notification to the system admin (#17090)
            Survey.sendFirstSchoolSurveyMail(survey);
          }
        });
      });
    }

    console.log('Intake - selected school / directors:', Survey.getSchool(), Survey.getDirectors());

    /** scope functions */

    $scope.onNext = () => {
      console.log('Next screen:', $scope.questions);

      const countNotFilled = $scope.questions.filter(qst => !qst.answer || qst.answer.length == 0).length;

      if (countNotFilled > 0) {
        // show error message -> missing answers
        $scope.feedbackMessage = 'Niet alle intake-vragen zijn beantwoord.';
        return;
      }

      // compute given answers to see which kind of advice show to the user
      // rule: . >= 80% with YES or 'stop intake qst' with YES => alert advice
      //       . otherwise just normal advice
      const totalQuestions = $scope.questions.length;
      const countYesAnswers = $scope.questions.filter(qst => isAnsweredYes(qst)).length;
      const countStopIntakeAnswers = $scope.questions.filter(qst => isAnsweredYes(qst) && qst.stopIntake).length;

      $scope.showAlertAdvice = countStopIntakeAnswers > 0 || (countYesAnswers * 100 / totalQuestions >= 80);

      $scope.adviceMessage = $scope.showAlertAdvice
        ? 'Op basis van uw antwoorden op de intake-vragen zijn wij van oordeel dat de WellBe-bevraging onvoldoende tegemoet komt aan de problemen die zich stellen op school of binnen de instelling. Daarom raden wij u aan om contact op te nemen met de preventieadviseur psychosociale aspecten. De directeur heeft ook deze boodschap ontvangen. Gelieve samen te overleggen over de te volgende stappen.'
        : 'De directeur is op de hoogte gebracht van deze melding via mail. <br> Je kan verder gaan met het afnemen van deze bevraging.';

      // intake advice popup is closed
      $scope.onClose = () => {
        if ($scope.showAlertAdvice) {
          // if an alert message was shown to the user then he has to enter a motivation to continue
          $location.path('/motivation');
        } else {
          $location.path('/enddate');
        }
      };

      console.log('Count yes answers:', countYesAnswers, countStopIntakeAnswers);

      // show intake advice popup
      const modalInstance = vskoModalService.open({
        template: require('./intakeAdvice.html'),
        scope: $scope
      });

      sendSurvey();
    };

    $scope.onChoiceSelected = (model) => {
      // console.log('Intake answer selected:', model);
    };
  }];
